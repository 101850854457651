import React from 'react';
import Layout from "../layout/layout";
import Seo from "../layout/seo";
import Breadcrumbs from "../components/breadcrumbs";
import Banner from "../services-page/banner";
import Featured from "../home/featured";
import Partner from "../home/partner";
import Locationlisting from "./locationlisting";

function Index(props) {
    return (
        <>
            <Layout>
                <Seo title='TekRevol Services' description='TekRevol Services'/>
                <Breadcrumbs link1="TekRevol" link2="Location"/>
                <Banner title="Location"/>
                <Locationlisting/>
                <Featured/>
                <Partner/>
            </Layout>
        </>
    );
}

export default Index;